import React from 'react';
import { FAQSection, OneAppSection } from '../sections';

import {
  Page,
  Section,
  BlankBox,
  BigGridBlock,
  Grid,
  SplitHero,
  FeatureBox,
  IncomeSources,
} from 'src/components';
import paths from 'src/constants/paths';

const HowItWorksPage = () => {
  return (
    <Page
      navBorder={false}
      path={paths.HOW_IT_WORKS}
      title="How Catch Works"
      image="/img/og/payroll.png"
      kw={[
        'link',
        'enroll',
        'save',
        'auto',
        'percentage',
        'withholding',
        'benefits',
        'automatic taxes',
        'health insurance',
        'time off',
        'retirement',
        'savings',
      ]}
    >
      <SplitHero
        eb="Personal Payroll"
        color="flare"
        title="We route your income. You reap the benefits."
        subtitle="Choose your benefits, link your bank, then set aside a percentage of each paycheck. It’s that simple."
        accessory={
          <BlankBox color="var(--g-cerise)" color2="var(--g-cerise)">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <img src="/new/uicard.webp" height={300} />
              <img src="/pig.webp" className="blender" height={300} style={{ marginTop: -64 }} />
            </div>
          </BlankBox>
        }
      >
        <div />
      </SplitHero>
      <Section
        center
        pretitle="Free personal payroll"
        title="With free Personal Payroll, you&nbsp;can:"
      >
        <Grid num={3}>
          <BigGridBlock
            glyph
            img="/iconic/layers.svg"
            title="Track your income"
            color="var(--g-cerise-light)"
            hoverColor="var(--g-cerise)"
          />
          <BigGridBlock
            glyph
            img="/iconic/bolt.svg"
            title="Automate withholding"
            color="var(--g-cerise-light)"
            hoverColor="var(--g-cerise)"
          />
          <BigGridBlock
            glyph
            img="/iconic/health.svg"
            title="Open up benefits"
            color="var(--g-cerise-light)"
            hoverColor="var(--g-cerise)"
          />
        </Grid>
      </Section>

      <OneAppSection></OneAppSection>

      <Section>
        <FeatureBox
          title="Earn from anywhere, all in one place"
          subtitle="Never worry about losing track of a payment again. Catch supports hundreds of apps and platforms."
          fullImage="sources.png"
          color="var(--c-cerise-1)"
          ebColor="var(--c-cerise-0)"
        >
          {/* <Collapse title="Marketplaces" />
          <Collapse title="Invoices" />
          <Collapse title="P2P" />
          <Collapse title="Checks" /> */}
        </FeatureBox>
        <IncomeSources />
        <FeatureBox
          title="Automatically set aside every time you’re paid"
          subtitle="We automate your withholding so you can focus on your passion."
          fullImage="roll.png"
          color="var(--c-cerise-1)"
          ebColor="var(--c-cerise-0)"
        >
          {/* <Collapse title="Customize your sources" />
          <Collapse title="Benefit breakdown" />
          <Collapse title="Separate and secure" /> */}
        </FeatureBox>
        <FeatureBox
          title="Get reliable benefits for an unpredictable future"
          subtitle="Enjoy benefits you can fall back on, no matter what life brings you."
          fullImage="benefits.png"
          color="var(--c-cerise-1)"
          ebColor="var(--c-cerise-0)"
        >
          {/* <Collapse title="Taxes" />
          <Collapse title="Health & Dental" />
          <Collapse title="Retirement" />
          <Collapse title="Time off & savings" /> */}
        </FeatureBox>
      </Section>

      <FAQSection />
    </Page>
  );
};

export default HowItWorksPage;
